<script lang="ts">
    import { onMount, tick } from 'svelte';
    import { inertia, router } from '@inertiajs/svelte';
    import * as DropdownMenu from '$lib/components/ui/dropdown-menu';
    import { DateTime } from 'luxon';
    import Litepicker from 'litepicker';
    import { Icon, Eye, Heart, UserGroup, Sparkles, Banknotes } from 'svelte-hero-icons';
    import axios from 'axios';
    import ApexCharts from 'apexcharts';
    export let currentUserId: string;
    export let cookies: string;

    let type: string = 'view';
    let selectedTypeLabel: string = 'Views';
    let selectedIcon: any = Eye;
    let selectedTimeframe: string = 'Last 7 Days';
    let timeframeValue: string = 'sevenDays';
    let scale: string = 'd';
    let source: string = 'post';
    let amount: number = 7;
    let offset: number = 0;
    let currentRoute = '';
    let chart: any;
    let chartContainer: HTMLElement;
    let datePicker: HTMLElement;

    const currentDate = new Date();

    let currentRange: any = { start: null, end: null };
    let formattedRange: any = { start: null, end: null };

    const currentYear = currentDate.getFullYear();
    const currentMonthNumber = currentDate.getMonth();
    const prevYear = currentYear - 1;
    const currentMonth = currentDate.toLocaleString('default', { month: 'long' });
    const prevMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1).toLocaleString('default', {
        month: 'long',
    });
    const prevPMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 2, 1).toLocaleString('default', {
        month: 'long',
    });

    const getMonthRange = (year: any, month: any) => {
        const firstDay = new Date(year, month, 1);
        const lastDay = new Date(year, month + 1, 0);
        return { start: firstDay, end: lastDay };
    };

    const currentMonthRange = getMonthRange(currentYear, currentMonthNumber);
    const prevMonthRange = getMonthRange(currentYear, currentMonthNumber - 1);
    const monthbeforePrevRange = getMonthRange(currentYear, currentMonthNumber - 2);

    const formatDate = (date: any) => DateTime.fromJSDate(date).toFormat('yyyy-MM-dd');

    const predefinedTimes = () => {
        let startDate: string, endDate: string;

        switch (timeframeValue) {
            case 'currentYear':
                startDate = `${currentYear}-01-01`;
                endDate = `${currentYear}-12-31`;
                break;
            case 'prevYear':
                startDate = `${prevYear}-01-01`;
                endDate = `${prevYear}-12-31`;
                break;
            case 'currentMonth':
                startDate = formatDate(currentMonthRange.start);
                endDate = formatDate(currentMonthRange.end);
                break;
            case 'prevMonth':
                startDate = formatDate(prevMonthRange.start);
                endDate = formatDate(prevMonthRange.end);
                break;
            case 'prevPMonth':
                startDate = formatDate(monthbeforePrevRange.start);
                endDate = formatDate(monthbeforePrevRange.end);
                break;
            default:
                startDate = `${currentYear}-01-01`;
                endDate = `${currentYear}-12-31`;
                break;
        }

        updateRangeAndChart(startDate, endDate);
    };

    router.reload({ only: ['currentUserId'] });

    let chartOptions = {
        chart: {
            height: 450,
            type: 'line',
            zoom: { enabled: false },
        },
        annotations: {
            yaxis: [
                {
                    y: 8200,
                    borderColor: '#00E396',
                    label: {
                        borderColor: '#00E396',
                        style: { color: '#fff', background: '#00E396' },
                        text: 'Support',
                    },
                },
            ],
        },
        dataLabels: { enabled: false },
        stroke: { curve: 'straight' },
        grid: {
            row: { colors: ['#f3f3f3', 'transparent'], opacity: 0.5 },
        },
        xaxis: {
            type: 'category',
            categories: [],
            labels: {
                format: 'dd.MM.',
            },
        },
        yaxis: { opposite: true },
        legend: { horizontalAlign: 'left' },
    };

    let series = [{ name: 'Desktops', data: [] }];

    const typedropdown = [
        { icon: Eye, item: 'Views', value: 'view' },
        { icon: Heart, item: 'Likes', value: 'like' },
        { icon: UserGroup, item: 'Follow', value: 'follow' },
    ];

    const timeframe = [
        { value: 'sevenDays', label: 'Last 7 Days' },
        { value: 'oneMonth', label: 'Last Month' },
        { value: 'threeMonths', label: 'Last 3 Month' },
        { value: 'oneYear', label: 'Last Year' },
        { value: 'currentYear', label: currentYear },
        { value: 'prevYear', label: prevYear },
        { value: 'currentMonth', label: currentMonth },
        { value: 'prevMonth', label: prevMonth },
        { value: 'prevPMonth', label: prevPMonth },
        { value: 'custom', label: 'Custom' },
    ];

    let periodValue = '';
    let periodPercent = '';
    let percentColor = '';
    let percentBackgroundColor = '';

    const handleItemChange = () => {
        if (timeframeValue === 'custom') {
            if (currentRange.start && currentRange.end) {
                updateChartCustom();
            }
        } else {
            if (
                timeframeValue === 'currentYear' ||
                timeframeValue === 'prevYear' ||
                timeframeValue === 'currentMonth' ||
                timeframeValue === 'prevMonth' ||
                timeframeValue === 'prevPMonth'
            ) {
                predefinedTimes();
            } else {
                updateChart();
            }
        }
    };

    const updateType = (newType: string, typeLabel: string, typeIcon: any) => {
        type = newType;
        selectedTypeLabel = typeLabel;
        selectedIcon = typeIcon;
        source = type === 'follow' ? 'profile' : 'post';
        handleItemChange();
    };

    const updateTimeframe = (selectedValue: any, selectedLabel: any) => {
        selectedTimeframe = selectedLabel;
        timeframeValue = selectedValue;

        switch (selectedValue) {
            case 'sevenDays':
                scale = 'd';
                amount = 7;
                break;
            case 'oneMonth':
                scale = 'd';
                amount = 30;
                break;
            case 'threeMonths':
                scale = 'd';
                amount = 90;
                break;
            case 'oneYear':
                scale = 'm';
                amount = 12;
                break;
            default:
                scale = 'd';
                amount = 7;
                break;
        }

        handleItemChange();
    };

    const updateChart = async () => {
        const response = await axios.get(
            `/analytics/v1/${source}/${currentUserId}/${type}?scale=${scale}&amount=${amount}&offset=${offset}`
        );

        if (response && response.status === 200) {
            const { data, labels, growth, last } = response.data;
            statsUpdate(last, growth);

            chart.updateSeries([
                {
                    name: selectedTypeLabel,
                    data: data.map((value: any, index: any) => ({
                        x: labels[index][0],
                        y: value,
                    })),
                },
            ]);

            chart.updateOptions({
                xaxis: {
                    categories: labels.map((label: any) => label[0]),
                },
            });

            console.log('Response', data, labels, growth, last);
        }
    };

    const updateChartCustom = async () => {
        if (currentRange.start && currentRange.end) {
            let apiUrl = `/analytics/v1/${source}/${currentUserId}/${type}?start_date=${encodeURIComponent(
                currentRange.start
            )}&end_date=${encodeURIComponent(currentRange.end)}`;

            const response = await axios.get(apiUrl);

            if (response && response.status === 200) {
                const { data, labels, growth, last } = response.data;

                chart.updateSeries([
                    {
                        name: selectedTypeLabel,
                        data: data.map((value: any, index: any) => ({
                            x: labels[index][0],
                            y: value,
                        })),
                    },
                ]);

                chart.updateOptions({
                    xaxis: {
                        categories: labels.map((label: any) => label[0]),
                    },
                });
            }
        }
    };

    const updateRangeAndChart = (startDate: string, endDate: string) => {
        formattedRange.start = startDate;
        formattedRange.end = endDate;
        currentRange.start = startDate + ' 00:00:00';
        currentRange.end = endDate + ' 23:59:59';
        updateChartCustom();
    };

    const statsUpdate = (stats: string, percent: number) => {
        let percentRound = Math.ceil(percent);
        periodValue = stats;
        percentColor = percent > 0 ? '#00FFA3' : percent < 0 ? '#ff0000' : 'rgba(128, 128, 128, 1)';
        percentBackgroundColor =
            percent < 0 ? 'rgba(255, 20, 64, 0.12)' : percent > 0 ? '' : 'rgba(117, 117, 117, 0.12)';
        const sign = percent > 0 ? '+' : '';
        periodPercent = `${sign}${percentRound}%`;
    };

    const initializeLitepicker = async () => {
        await tick();
        if (datePicker) {
            const picker = new Litepicker({
                element: datePicker,
                delimiter: ' - ',
                singleMode: false,
                numberOfMonths: 2,
                showTooltip: true,
                numberOfColumns: 2,
                startDate: new Date(),
                setup: (picker) => {
                    picker.on('show', () => {
                        let currentDate = new Date();
                        currentDate.setDate(1);
                        currentDate.setMonth(currentDate.getMonth() - 1);
                        picker.gotoDate(currentDate);
                    });
                },
            });

            console.log('Picker', picker);
        } else {
            console.log('DatePicker Element not found');
        }
    };

    onMount(() => {
        currentRoute = window.location.pathname;
        chart = new ApexCharts(chartContainer, {
            series,
            ...chartOptions,
        });
        chart.render();

        handleItemChange();

        initializeLitepicker();
    });
</script>

<div class="min-vh-100 pr-0 pt-4">
    <div class="px-4">
        <h5 class="text-truncate mb-0 font-bold dark:text-white {cookies}">Analytics Dashboard</h5>
    </div>

    <div class="inline-border-tabs mb-1">
        <nav class="nav nav-pills nav-justified text-bold mt-3">
            <a
                class="nav-item flex gap-x-1 items-center justify-center nav-link {currentRoute === `/analytics/engagement` ? 'active' : ''}"
                href="{`/analytics/engagement`}"
                use:inertia
            >
                <Icon src="{Sparkles}" size="{'22px'}" />
                Engagement
            </a>
            <a
                class="nav-item nav-link flex gap-x-1 items-center justify-center {currentRoute === `/analytics/revenue` ? 'active' : ''}"
                href="{`/analytics/revenue`}"
                use:inertia
            >
                <Icon src="{Banknotes}" size="{'22px'}" />
                Revenue
            </a>
        </nav>
    </div>

    <div class="px-3 py-1">
        <div class="toggle-analyt">
            <div class="flex gap-x-2">
            <DropdownMenu.Root>
                <DropdownMenu.Trigger class="px-4 py-2 rounded-md bg-neutral-100 dark:bg-neutral-800 flex">
                    <Icon src="{selectedIcon}" size="{'22px'}" solid />&nbsp;&nbsp;{selectedTypeLabel}
                </DropdownMenu.Trigger>
                <DropdownMenu.Content>
                    <DropdownMenu.Group>
                        {#each typedropdown as type}
                            <DropdownMenu.Item
                                on:click="{() => updateType(type.value, type.item, type.icon)}"
                                class="flex"
                            >
                                <Icon src="{type.icon}" size="{'22px'}" solid />&nbsp;&nbsp;{type.item}
                            </DropdownMenu.Item>
                        {/each}
                    </DropdownMenu.Group>
                </DropdownMenu.Content>
            </DropdownMenu.Root>

            <DropdownMenu.Root>
                <DropdownMenu.Trigger class="px-4 py-2 rounded-md bg-neutral-100 dark:bg-neutral-800 flex">{selectedTimeframe}</DropdownMenu.Trigger>
                <DropdownMenu.Content>
                    <DropdownMenu.Group>
                        {#each timeframe as time}
                            <DropdownMenu.Item on:click="{() => updateTimeframe(time.value, time.label)}"
                                >{time.label}</DropdownMenu.Item
                            >
                        {/each}
                    </DropdownMenu.Group>
                </DropdownMenu.Content>
            </DropdownMenu.Root>

            </div>

            <div class="stats mt-3">
                <span class="period-stats text-lg text-black dark:!text-white">{amount}{scale}</span>
                <div class="d-flex stats-values relative items-center">
                    <div id="valueLoader" class="absolute bottom-0 left-0 top-0 z-50 hidden w-20 rounded-lg">
                        <div class="h-full w-full animate-pulse">
                            <div class="h-full w-full rounded-lg bg-gray-200"></div>
                        </div>
                    </div>
                    <span class="period-value font-mona-expanded text-6xl font-black text-black dark:!text-white">
                        {periodValue}
                    </span>&nbsp;&nbsp;&nbsp;
                    <span
                        class="period-percent rounded-md px-2 py-1 text-lg font-bold"
                        style="color: {percentColor}; background-color: {percentBackgroundColor}"
                    >
                        {periodPercent}
                    </span>
                </div>
            </div>

            <div>
                <div id="pointwithdrawal">
                    <div id="iconContainer" style="display: none"></div>
                </div>

                {#if timeframeValue === 'custom'}
                    <div class="date-ranger-picker">
                        <input
                            type="text"
                            bind:this="{datePicker}"
                            placeholder="YYYY-MM-DD - YYYY-MM-DD"
                            class="lite-input form-control h-9 w-56 rounded-md text-center text-sm shadow-none focus:border-bmn-500"
                        />
                    </div>
                {/if}

                <div class="stats mt-3">
                    <span class="period-stats text-lg text-black dark:!text-white"></span>
                    <div class="d-flex stats-values relative items-center">
                        <div id="valueLoader" class="absolute bottom-0 left-0 top-0 z-50 hidden w-20 rounded-lg">
                            <div class="h-full w-full animate-pulse">
                                <div class="h-full w-full rounded-lg bg-gray-200"></div>
                            </div>
                        </div>
                        <span class="period-value font-mona-expanded text-6xl font-black text-black dark:!text-white"
                        ></span>&nbsp;&nbsp;&nbsp;
                        <span
                            class="period-percent rounded-md bg-green-500 bg-opacity-10 px-2 py-1 text-lg font-bold text-teal-400"
                        ></span>
                    </div>
                </div>

                <div id="chart-container" style="position: relative; top: 4px">
                    <div
                        id="chartLoader"
                        class="absolute bottom-0 left-0 right-0 top-0 z-50 hidden !h-full !w-full rounded-lg"
                    >
                        <div class="h-full w-full animate-pulse">
                            <div class="!h-full !w-full rounded-lg bg-gray-200"></div>
                        </div>
                    </div>
                    <div bind:this="{chartContainer}" id="chart"></div>
                </div>

                <div class="best-performance-posts mt-5 px-3 py-2">
                    <h5 class="font-bold text-black dark:!text-stone-200">Best Performing Posts</h5>
                    <div class="posts-main"></div>
                </div>
            </div>
        </div>
    </div>
</div>
